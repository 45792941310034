<template>
  <div class="share" ref="self-main">
    
    <!-- <section>
      <div class="container">
        <button class="add-friend-btn" @click="openAddFriendModal"><img :src="require('@/assets/icons/Add_round_fill.png')" />增加好友</button>
      </div>
    </section> -->

    <section>
      <div class="container" ref="main-list">
        <p class="text-center text-secondary mt-4" v-if="homeOutfitSerials.length === 0">尚未追蹤任何使用者<br/>或未有任何追蹤中的使用者分享穿搭</p>
        <div class="row no-gutters">
          <div class="col-6 px-1 mb-2" v-for="(s) in filteredSerial" :key="s">
            <OutfitCard :serial="s"></OutfitCard>
          </div>
        </div>
        <div class="mb-3 text-center" v-if="showLoadMoreBtn">
          <button class="btn btn-sm btn-green" @click="loadMoreClicked">載入更多</button>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex';
import OutfitCard from '@/components/home/OutfitCard.vue';
import _ from 'lodash';

export default {
  name: 'FriendOutfits',
  data() {
    return {
      readingBusyName: 'READINGOUTFITSERIALS',

      init: false,
      homeOutfitSerials: [],
      nowLoadCount: 6,
    };
  },
  props: {
    scrollTop: {
      type: Number,
    },
    routerViewHeight: {
      type: Number,
    }
  },
  components: {
    OutfitCard,
  },
  computed: {
    filteredSerial() {
      const res = [];
      for (let i=0;i<this.nowLoadCount && i<this.homeOutfitSerials.length;i++) {
        res.push(this.homeOutfitSerials[i]);
      }
      return res;
    },
    showLoadMoreBtn() {
      return this.nowLoadCount < this.homeOutfitSerials.length;
    },

  },
  watch: {
    scrollTop(val) {
      this.scrollEvent(val);
    },
  },
  async mounted() {
    await this.__refresh();
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
    async __refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        this.homeOutfitSerials.splice(0, this.homeOutfitSerials.length);
        const serials = await this.$store.dispatch('api/getHomeOutfitSerialsPromise');
        for (const s of serials) {
          this.homeOutfitSerials.push(s);
        }
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    // closeAddFriendModal() {
    //   this.showAddFriendModal = false;
    // },
    openAddFriendModal() {
      this.setAddFriendShow(true);
      // this.showAddFriendModal = true;
    },
    loadMoreClicked() {
      this.nowLoadCount += 6;
    },
    scrollEvent: _.debounce(async function (scrollTop) {
      // console.log(scrollTop + this.routerViewHeight);
      // console.log(this.$refs['main-list'].clientHeight);
      if (scrollTop + this.routerViewHeight > this.$refs['main-list'].clientHeight) {
        this.loadMoreClicked();
      }
    }, 100),
  }
}
</script>

<style scoped>
  .share {
    padding: 1rem 0;
  }

  .add-friend-btn {
    border: none;
    border-radius: .5rem;
    background-color: var(--main-yellow);
    padding: .3rem;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-appearance: none;
    width: 100%;
    color: #fff;
  }

  .add-friend-btn>img {
    display: block;
    margin-right: .5rem;
    width: 20px;
    height: 20px;
  }

  .public-closet-list {
    padding: 1rem 0;
  }

  .public-closet-list h2 {
    font-size: 18px;
    font-weight: 1000;
    color: var(--text-dark);
  }

  .snippest-block {
    display: block;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 3px 3px 2px 0 #EEEEEE;
    margin-bottom: .5rem;
  }

  .snippest-block:hover {
    text-decoration: none;
  }


</style>
